<template>
  <div class="app-container">
    <el-main>
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="85px" label-position="left">
        <el-row>
          <el-col :span="6">
            <el-form-item label="模版名称" prop="name">
              <el-input
                  style="width: 240px"
                  v-model="queryParams.name"
                  placeholder="请输入模版名称"
                  clearable
                  @keyup.enter.native="handleQuery"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="项目类型" prop="projectTypeId">
              <el-select style="width: 240px" v-model="queryParams.projectTypeId" filterable clearable placeholder="请选择项目类型">
                <el-option
                    v-for="item in productTypeOptions"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button type="primary" @click="handleQuery">搜索</el-button>
              <el-button type="warning" @click="resetQuery">刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-row :gutter="10" class="mb8" justify="end">
        <el-col :span="1.5">
          <el-button
              type="primary"
              @click="handleAdd"
          >新增</el-button>
        </el-col>
      </el-row>

      <el-table v-loading="loading" :data="surveyTemplateList" @selection-change="handleSelectionChange">
        <el-table-column width="100">
          <template #default="scope">
            <div style="display:flex;align-items:center;justify-content:center">
              <el-image style="width: 49px; height: 49px;" :src="backImgUrl" fit="contain"/>
              <el-image style="width: 23px; height: 22px;position:absolute" :src="imgUrl" fit="contain"/>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template #default="scope">
            <el-row :span="24">
              <el-col :span="12">
                <span class="title">{{ scope.row.name }}</span>
              </el-col>
              <el-col :span="12" style="text-align:right">
                <el-tooltip content="参数详情">
                  <el-icon :size="20" style="margin-right:10px" @click="showSurveyParam(scope.row)">
                    <Tickets  color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="编辑">
                  <el-icon :size="20" style="margin-right:10px" @click="handleUpdate(scope.row)">
                    <Edit color="#00B7FF" />
                  </el-icon>
                </el-tooltip>

                <el-tooltip content="删除">
                  <el-icon :size="20" color="#ff0000" @click="handleDelete(scope.row)">
                    <Delete/>
                  </el-icon>
                </el-tooltip>
              </el-col>
            </el-row>
            <el-row :span="24">
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目类型：">{{ scope.row.projectType.name }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="调研参数详情：">
                    <el-button type="primary" text @click="showSurveyParam(scope.row)"  :key="scope.row.paramCount">{{ scope.row.paramCount }}</el-button>
                  </el-descriptions-item>
                </el-descriptions>
              </el-col>
              <el-col :span="8">
                <el-descriptions :column="1">
                  <el-descriptions-item label="项目数量：">{{ scope.row.projectCount }}</el-descriptions-item>
                </el-descriptions>
              </el-col>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="total>0"
          :total="total"
          v-model:page="queryParams.pageNum"
          v-model:limit="queryParams.pageSize"
          @pagination="getList"
      />

      <!-- 添加或修改调研模版对话框 -->
      <el-dialog :title="title" v-model="open" width="500px">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="模版名称" prop="name">
            <el-input v-model="form.name" maxlength="10" placeholder="请输入模版名称" style="width: 100%" />
          </el-form-item>
          <el-form-item label="项目类型" prop="projectTypeId">
            <el-select style="width: 100%" v-model="form.projectTypeId" filterable placeholder="请选择项目类型">
              <el-option
                  v-for="item in productTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel">取 消</el-button>
            <el-button type="primary" @click="submitForm" :disabled="submitting">确 定</el-button>
          </span>
        </template>
      </el-dialog>
      <survey-param-index :id="editId" v-model:visible="isShowSurveyParam" v-if="isShowSurveyParam" @completed="getList"></survey-param-index>
    </el-main>
  </div>
</template>

<script>
import pagination from "@/components/Pagination/index.vue";
import { listSurveyTemplate, getSurveyTemplate, delSurveyTemplate, addSurveyTemplate, updateSurveyTemplate } from "@/api/survey_template";
import { getProjectTypeOptions} from "@/api/project_type"
import { isResOK } from "@/api/response";
import SurveyParamIndex from "@/view/survey_param/index.vue";
import store from "@/store";
import {getUrlParams} from "@/api/userList";
import {getCurrentUser} from "@/api/user";
export default {
  name: "Survey_template",
  components:{SurveyParamIndex, pagination},
  data() {
    return {
      submitting: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 调研模版表格数据
      surveyTemplateList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: null,
        projectTypeId: null,
        projectCount: null,
      },
      // 表单参数
      form: {
        name: '',
        projectTypeId: 0,

      },
      editId: 0,
      isShowSurveyParam: false,
      // 表单校验
      rules: {
        name: [
          { required: true, message: "模版名称不能为空", trigger: "blur" }
        ],
        projectTypeId: [
          { required: true, message: "项目类型不能为空", trigger: "blur" }
        ],
      },
      productTypeOptions:[],
      imgUrl: require(`/src/assets/survey_template.png`),
      backImgUrl: require(`/src/assets/project_back.svg`),
    };
  },
  created() {
    this.getProductTypeOptions()
    this.getList()
    this.fetchCurrentUser()
  },
  methods: {
    fetchCurrentUser() {
      store.dispatch('user_info/cleanUser')
      let data = {}
      const params = getUrlParams(window.location.href)
      if (params[`accessToken`]) {
        sessionStorage.setItem('token', params[`accessToken`])
        data[`auth-token`] = params[`accessToken`]
        getCurrentUser(data).then(res => {
          if (isResOK(res)) {
            store.dispatch('user_info/setUser', res.data)
          }
        })
      }
    },

    /** 查询调研模版列表 */
    getList() {
      this.loading = true;
      listSurveyTemplate(this.queryParams).then(response => {
        this.surveyTemplateList = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    getProductTypeOptions(){
      getProjectTypeOptions().then(res =>{
        if (isResOK(res)) {
          this.productTypeOptions = res.data
        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        name: null,
        projectTypeId: null,
        projectCount: null,
        createTime: null,
        updateTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加调研模版";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getSurveyTemplate(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改调研模版";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.submitting = true
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateSurveyTemplate(Object.assign({}, this.form, Object.assign({}, this.formState, { operatingMode: 'web' }))).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
              }
            }).finally(() => {
              this.submitting = false
            })
          } else {
            addSurveyTemplate(Object.assign({}, this.form, Object.assign({}, this.formState, { operatingMode: 'web' }))).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '新增成功',
                  type: 'success',
                })
                this.open = false;
                this.getList();
              }
            }).finally(() => {
              this.submitting = false
            })
          }
        } else {
          this.submitting = false
        }
      }).finally(() => {
        this.submitting = false
      })
      this.getProductTypeOptions()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$messageBox.confirm(
          '是否确认删除调研模版"' + row.name +'"',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delSurveyTemplate(ids, { operatingMode: 'web' }).then((res) => {
          if (isResOK(res)) {
            this.$message({
              message: '删除成功',
              type: 'success',
            })
          }
          this.getList()
        })
      }).catch(() => {})
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('business/survey_template/export', {
        ...this.queryParams
      }, `survey_template_${new Date().getTime()}.xlsx`)
    },
    /** 调研参数数量点击 */
    showSurveyParam(row){
      this.editId = row.id
      this.isShowSurveyParam = true
    },
  }
};
</script>
<style scoped lang="less">
.title {
  font-size: 17px;
  color: #000000;
  font-weight: bold;
}
</style>
