import request from '@/utils/request'

// 查询调研模版列表
export function listSurveyTemplate(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template/list',
        method: 'get',
        params: query
    })
}

// 查询调研模版详细
export function getSurveyTemplate(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template/' + id,
        method: 'get'
    })
}

// 新增调研模版
export function addSurveyTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template',
        method: 'post',
        data: data
    })
}

// 修改调研模版
export function updateSurveyTemplate(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template',
        method: 'put',
        data: data
    })
}

// 删除调研模版
export function delSurveyTemplate(id, data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template/' + id,
        method: 'delete',
        data: data
    })
}

// 查询调研模版选项
export function getSurveyTemplateOptions(projectTypeId) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template/options?projectTypeId='+projectTypeId,
        method: 'get',
    })
}

// 查询调研模版参数
export function getSurveyParams(surveyTemplateId) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_template/'+surveyTemplateId+'/params',
        method: 'get',
    })
}
