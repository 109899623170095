import request from '@/utils/request'

// 查询调研参数列表
export function listSurveyParam(query) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/list',
        method: 'get',
        params: query
    })
}

// 查询调研参数详细
export function getSurveyParam(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/' + id,
        method: 'get'
    })
}

// 新增调研参数
export function addSurveyParam(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param',
        method: 'post',
        data: data
    })
}

// 修改调研参数
export function updateSurveyParam(data) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param',
        method: 'put',
        data: data
    })
}

// 删除调研参数
export function delSurveyParam(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/' + id,
        method: 'delete'
    })
}

// 参数类型选项
export function surveyParamTypeOptions() {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/type/options',
        method: 'get',
    })
}

// 置顶
export function topSurveyParam(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/'+ id+'/top',
        method: 'put',
    })
}

// 下移
export function downSurveyParam(id) {
    return request({
        url: process.env.VUE_APP_BASE_API + '/api/business/survey_param/'+ id+'/down',
        method: 'put',
    })
}
