<template>
  <el-dialog
      v-model="isShow"
      title="调研参数详情"
      width="50%"
      :before-close="handleClose"
  >
    <template #default>
      <el-row :gutter="20" class="mb8" justify="end" style="margin-bottom: 20px">
        <el-col :span="1.5">
          <el-button
              type="primary"
              @click="handleAddParam"
          >新增</el-button>
        </el-col>
      </el-row>
      <el-table :data="data" row-key="id" border height="350" v-loading="loading">
        <el-table-column prop="name" label="参数名称" width="200" />
        <el-table-column prop="paramTypeDisplay" label="输入方式" width="150" />
        <el-table-column label="值" width="200">
          <template #default="scope">
            {{scope.row.paramValue === '' ? '-' : scope.row.paramValue}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template #default="scope">
            <el-tooltip content="置顶" v-if="scope.row.sortNum !== 1">
              <el-icon :size="20" style="margin-right:10px" @click="topping(scope.row)" >
                <Top color="#00B7FF" />
              </el-icon>
            </el-tooltip>
            <el-icon v-else :size="20" style="margin-right:10px"></el-icon>

            <el-tooltip content="下移" v-if="scope.row.sortNum !== data.length">
              <el-icon :size="20" style="margin-right:10px" @click="down(scope.row)">
                <Bottom color="#00B7FF" />
              </el-icon>
            </el-tooltip>
            <el-icon v-else :size="20" style="margin-right:10px"></el-icon>

            <el-tooltip content="编辑">
              <el-icon :size="20" style="margin-right:10px" @click="handleUpdate(scope.row)">
                <Edit color="#1D98DC" />
              </el-icon>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
          v-model="innerVisible"
          width="500px"
          :title="title"
          append-to-body
      >
        <el-form ref="formRef" :inline="false" :model="formState"  :rules="rules" label-width="80px">
          <el-form-item label="参数名称" prop="name" >
            <el-input style="width: 100%" maxlength="10" v-model="formState.name" placeholder="请输入参数名称" clearable />
          </el-form-item>
          <el-form-item label="输入类型" prop="paramType" >
            <el-select style="width: 100%" v-model="formState.paramType" filterable placeholder="请选择输入类型" @change="changeParamType">
              <el-option
                  v-for="item in surveyParamTypeOption"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="值" prop="paramValue" v-if="paramValueShow === true">
            <el-input v-model="formState.paramValue"
                      maxlength="1000"
                      :rows="3"
                      type="textarea"
                      placeholder="请输入值，格式：key:value,key:value" clearable />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="submitForm" :disabled="submitting">确定</el-button>
            <el-button @click="handleCloseInner">取消</el-button>
          </span>
        </template>
      </el-dialog>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
      </span>
    </template>
  </el-dialog>

</template>

<script>
import {
  addSurveyParam, downSurveyParam,
  getSurveyParam,
  listSurveyParam,
  surveyParamTypeOptions, topSurveyParam,
  updateSurveyParam
} from "@/api/survey_param";
import {isResOK} from "@/api/response";

export default {
  name: "SurveyParamIndex",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    }
  },
  data(){
    return {
      submitting: false,
      innerVisible: false,
      paramValueShow: false,
      rules: {
        name: [
          { required: true, message: "参数名称不能为空", trigger: "blur" }
        ],
        paramType: [
          { required: true, message: "输入类型不能为空", trigger: "change" }
        ],
        paramValue: [
          { required: true, message: "参数值枚举不能为空", trigger: "blur" }
        ],
      },
      formState: {},
      data: [],
      loading: false,
      surveyParamTypeOption: [],
      title:'',
    }
  },
  emits: ['update:visible', 'completed'],
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.getList()
    this.getSurveyParamTypeOptions()
  },
  methods: {
    getSurveyParamTypeOptions(){
      surveyParamTypeOptions().then((res) => {
        if (isResOK(res)){
          this.surveyParamTypeOption = res.data
        }
      })
    },
    getList(){
      this.loading = true
      listSurveyParam({surveyTemplateId: this.id}).then(res => {
        if (isResOK(res)){
          this.data = res.rows
        }
        this.loading = false
        this.submitting = false
      })
    },
    handleClose(){
      this.$emit('completed')
      this.isShow = false
    },
    handleAddParam(){
      this.reset()
      this.formState.sortNum = this.data.length + 1
      this.innerVisible = true
      this.title = "添加调研参数";
    },
    handleCloseInner(){
      this.innerVisible = false
      this.reset();
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getSurveyParam(id).then(response => {
        this.formState = response.data;
        this.innerVisible = true;
        this.title = "修改调研参数";
        let typeValue = ""
        this.surveyParamTypeOption.forEach(option => {
          if (option.id === this.formState.paramType){
            typeValue = option.value
          }
        })
        if (["radio","checkbox","select"].includes(typeValue)){
          this.paramValueShow = true
        }else{
          this.paramValueShow = false
        }
      });
    },
    // 表单重置
    reset() {
      this.formState = {
        id: null,
        name: null,
        paramType: null,
        paramValue: null,
        surveyTemplateId: this.id,
        sortNum: null,
        createTime: null,
        updateTime: null
      };
      this.paramValueShow = false
      this.resetForm("formRef");
    },
    submitForm(){
      this.submitting = true
      this.$refs["formRef"].validate(valid => {
        if (valid) {
          if (this.formState.id != null) {
            updateSurveyParam(this.formState).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '修改成功',
                  type: 'success',
                })
                this.innerVisible = false;
                this.getList();
              }
            }).finally(() => {
              this.submitting = false
            });
          } else {
            addSurveyParam(this.formState).then(response => {
              if (isResOK(response)){
                this.$message({
                  message: '新增成功',
                  type: 'success',
                })
                this.innerVisible = false;
                this.getList();
              }
            }).finally(() => {
              this.submitting = false
            })
          }
        } else {
          this.submitting = false
        }
      }).finally(()=> {
        this.submitting = false
      })
    },
    topping(row){
      topSurveyParam(row.id).then(res => {
        if (isResOK(res)){
          this.$message({
            message: '置顶成功',
            type: 'success',
          })
          this.getList();
        }
      })
    },
    down(row){
      downSurveyParam(row.id).then(res => {
        if (isResOK(res)){
          this.$message({
            message: '下移成功',
            type: 'success',
          })
          this.getList();
        }
      })
    },
    changeParamType(id){
      this.formState.paramValue = undefined
      let typeValue = ""
      this.surveyParamTypeOption.forEach(option => {
        if (option.id === id){
          typeValue = option.value
        }
      })
      if (["radio","checkbox","select"].includes(typeValue)){
        this.paramValueShow = true
      }else{
        this.paramValueShow = false
      }
    }
  },
}
</script>

<style scoped>

</style>
